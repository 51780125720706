import { Box, Grid, Pagination, Typography } from "@mui/material";
import React, { useState } from "react";
import ManifestOverviewCard from "./ManifestOverviewCard";

const PaginatedCardsView = ({ data }) => {
  const [page, setPage] = useState(1);
  const cardsPerPage = 4; // Number of cards to display per page
  const totalCards = data.length; // Total number of cards

  // Calculate the index of the first and last card to display on the current page
  const indexOfLastCard = page * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentData = data.slice(indexOfFirstCard, indexOfLastCard);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      {currentData?.length > 0 ? (
        <>
          <Grid container spacing={8} mt={1} sx={{ justifyContent: "center" }}>
            {currentData &&
              currentData.map((manifest) => (
                <Grid item xs={12} md={4} lg={3}>
                  <ManifestOverviewCard manifest={manifest} />
                </Grid>
              ))}
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", m: 5 }}>
            <Typography variant="body1" sx={{ mr: 2, mt: 0.5, fontSize: "16px", lineHeight: "150%" }}>
              {indexOfFirstCard + 1}-{indexOfFirstCard + currentData.length} of {totalCards}
            </Typography>
            <Pagination count={Math.ceil(totalCards / cardsPerPage)} page={page} onChange={handlePageChange} />
          </Box>
        </>
      ) : (
        <Box display="flex" justifyContent="center" sx={{ mt: 8 }}>
          <Typography variant="h3">No Manifests Found</Typography>
        </Box>
      )}
    </>
  );
};

export default PaginatedCardsView;
