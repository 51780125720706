import { Box, Grid, Typography, Button, Card, Divider, List } from "@mui/material";
import CurrencyFormat from "react-currency-format";
import { Scrollbars } from "react-custom-scrollbars-2";
import Zoom from "react-medium-image-zoom";
import { Link } from "react-router-dom";

export const CascadingSearchCard = ({ cascadeDetails }) => {
  const { label, primary, evidence } = cascadeDetails;

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Card
        elevation={3}
        sx={{
          height: "100%",
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "column",
        }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Typography
              variant="h5"
              color="text.primary"
              sx={{
                fontSize: 16,
              }}>
              {label}
            </Typography>
            <Button variant="outlined" target="_blank" color="primary" component={Link} to={`/manifests/${primary?.manifestId}/review/${primary?.id}`}>
              ${primary?.totalPrice?.toFixed(2)}
            </Button>
          </Box>
          <Box mt={3}>
            {primary?.manifestEntryCatalyticConverters
              ?.sort((a, b) => (a?.name < b?.name ? 1 : -1))
              ?.map((cat) => {
                return (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        pb: 1,
                      }}>
                      <Typography>{cat?.code ?? `N/A`}</Typography>
                      <Typography>${cat?.price?.toFixed(2)}</Typography>
                    </Box>
                    <Divider
                      sx={{
                        mb: 2,
                      }}
                    />
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Zoom zoomImg={{ src: cat?.frontImage?.url }}>
                        <Box sx={{ maxHeight: 64, maxWidth: 64 }} component="img" src={cat?.frontImage?.thumbnailUrl} />
                      </Zoom>
                      <Zoom zoomImg={{ src: cat?.backImage?.url }}>
                        <Box sx={{ maxHeight: 64, maxWidth: 64 }} component="img" src={cat?.backImage?.thumbnailUrl} />
                      </Zoom>
                      <Zoom zoomImg={{ src: cat?.codeImage?.url }}>
                        <Box sx={{ maxHeight: 64, maxWidth: 64 }} component="img" src={cat?.codeImage?.thumbnailUrl} />
                      </Zoom>
                      {cat?.alternativeImages?.map((image) => {
                        return (
                          <Zoom zoomImg={{ src: image?.url }}>
                            <Box sx={{ maxHeight: 64, maxWidth: 64 }} component="img" src={image?.thumbnailUrl} />
                          </Zoom>
                        );
                      })}
                    </Box>
                  </>
                );
              })}
          </Box>
        </Box>
        <Box>
          <Box mt={3}>
            <Typography
              variant="h5"
              color="text.primary"
              sx={{
                fontWeight: 600,
                fontSize: 18,
                letterSpacing: 0.46,
              }}>
              RESULTS: {evidence?.length}
            </Typography>
          </Box>
          <Scrollbars
            style={{
              height: 200,
              justifyContent: "flex-end",
            }}>
            <List
              sx={{
                width: "100%",
                position: "relative",
                overflow: "auto",
                "& ul": { padding: 0 },
                pr: 2,
              }}
              subheader={<li />}>
              {evidence
                ?.sort((a, b) => (a?.totalPrice < b?.totalPrice ? 1 : -1))
                .map((ev) => {
                  return (
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.primary"
                        sx={{
                          fontSize: 12,
                        }}>
                        {ev?.vehicle?.year} {ev?.vehicle?.make} {ev?.vehicle?.model} {ev?.vehicle?.engineLiters}L
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <Box
                          sx={{
                            flex: 2,
                          }}>
                          {ev?.manifestEntryCatalyticConverters
                            ?.sort((a, b) => (a?.price < b?.price ? 1 : -1))
                            ?.map((cat) => {
                              return (
                                <>
                                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography
                                      color="text.primary"
                                      variant="caption"
                                      sx={{
                                        textTransform: "uppercase",
                                      }}>
                                      {cat?.code ?? `N/A`}
                                    </Typography>
                                    <Typography
                                      color="text.primary"
                                      sx={{
                                        fontSize: 12,
                                        fontWeight: 700,
                                      }}>
                                      <CurrencyFormat fixedDecimalScale decimalScale={2} value={cat?.price} displayType={"text"} thousandSeparator={true} prefix={"$"} />
                                    </Typography>
                                  </Box>
                                </>
                              );
                            })}
                        </Box>
                        <Box
                          sx={{
                            flex: 1,
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}>
                          <Button size="small" target="_blank" variant="outlined" color="primary" component={Link} to={`/manifests/${ev?.manifestId}/review/${ev?.id}`}>
                            <CurrencyFormat fixedDecimalScale decimalScale={2} value={ev?.totalPrice} displayType={"text"} thousandSeparator={true} prefix={"$"} />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
            </List>
          </Scrollbars>
        </Box>
      </Card>
    </Grid>
  );
};
