import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Box, Button, Card, Chip, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Tab, Typography, useTheme, IconButton, Tooltip } from "@mui/material";
import { CheckCircle, ChevronLeft, ChevronRight, Close, FlagCircle, Numbers, Pending, Percent } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import SubmissionTable from "../ui-components/SubmissionsTable";
import manifestService from "../services/manifestService";
import { EXHUAST_DIAGRAMS, MANIFEST_ENTRY_STATUS, QUERIES } from "../utils/constants";
import { useMutation, useQuery, useQueryClient } from "react-query";
import OverlayLoader from "../ui-components/OverlayLoader";
import { enqueueSnackbar } from "notistack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export const ManifestReview = () => {
  const { id, entryId } = useParams();
  const [copiedTooltip, setCopiedTooltip] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: manifestData, isLoading: manifestLoading } = useQuery([QUERIES.Manifests, id], () => manifestService.get(id), {
    enabled: !!id,
  });

  const { data: manifestEntryData, isLoading: manifestEntryLoading } = useQuery([QUERIES.ManifestEntries, entryId], () => manifestService.getEntry(entryId), {
    enabled: !!entryId,
  });

  const updateEntryStatus = useMutation({
    mutationFn: (statusId) => {
      return manifestService.updateStatus(entryId, statusId);
    },
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [tabValue, setTabValue] = useState("1");

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseDialog = () => {
    setSelectedImage(null);
  };

  const handleVinCopy = () => {
    navigator.clipboard.writeText(manifestEntry?.vin);
    setCopiedTooltip(true);
  };

  const handleUpdateEntryStatus = async (statusId) => {
    await updateEntryStatus.mutateAsync(statusId);
    queryClient.invalidateQueries([QUERIES.ManifestEntries, entryId]);
    queryClient.invalidateQueries([QUERIES.Manifests, id]);

    if (statusId != MANIFEST_ENTRY_STATUS.IN_PROGRESS) {
      handleNext();
      enqueueSnackbar("Entry Updated Successfully.", { variant: "success" });
    }
  };

  const handleNext = () => {
    const nextEntry = manifestData?.data?.result?.manifestEntries?.find((entry) => entry?.id > entryId);
    navigate(`/manifests/${id}/review/${nextEntry?.id}`);
  };

  const handlePrevious = () => {
    // order by the entry id and find the previous entry
    // const previousEntry = manifestData?.data?.result?.manifestEntries?.find((entry) => entry?.id < entryId);
    const lastEntry = manifestData?.data?.result?.manifestEntries
      ?.filter((entry) => entry.id < entryId)
      ?.sort((a, b) => a.id - b.id)
      ?.pop();

    navigate(`/manifests/${id}/review/${lastEntry?.id}`);
  };

  var manifest = manifestData?.data?.result;
  var manifestEntry = manifestEntryData?.data?.result;

  var manifestEntryCats = manifestEntry?.manifestEntryCatalyticConverters?.filter((cat) => cat?.enabled)?.length;
  var manifestEntryCatsMapped = manifestEntry?.manifestEntryCatalyticConverters?.filter((cat) => cat?.converterConnectionsCatalyticConverter)?.length;

  var enableApprove = manifestEntryCats === manifestEntryCatsMapped;

  var motoDataDiagrams = manifestEntry?.vehicle?.exhaustDiagrams?.filter((diagram) => diagram?.exhaustDiagramType?.id == EXHUAST_DIAGRAMS.MOTOR_DATA);
  var apDataDiagrams = manifestEntry?.vehicle?.exhaustDiagrams?.filter((diagram) => diagram?.exhaustDiagramType?.id == EXHUAST_DIAGRAMS.AP_EXHAUST);

  const groupedMotoData = [];

  motoDataDiagrams?.forEach((diagram) => {
    const diagramUrl = diagram.diagramUrl;
    const existingGroup = groupedMotoData.find((group) => group.diagramUrl === diagramUrl);
    if (existingGroup) {
      const partApplicationId = diagram.partApplicationId;
      if (partApplicationId !== null && partApplicationId !== undefined && !existingGroup.positions.includes(partApplicationId)) {
        existingGroup.positions.push(partApplicationId);
      }
    } else {
      const newGroup = {
        diagramUrl: diagramUrl,
        descriptionMatch: diagram.descriptionMatch,
        positions: [],
      };
      const partApplicationId = diagram.partApplicationId;
      if (partApplicationId !== null && partApplicationId !== undefined) {
        newGroup.positions.push(partApplicationId);
      }
      groupedMotoData.push(newGroup);
    }
  });

  useEffect(() => {
    const handleUpdateStatus = async () => {
      if (manifestEntry?.status?.id === MANIFEST_ENTRY_STATUS.DRAFT) {
        await handleUpdateEntryStatus(MANIFEST_ENTRY_STATUS.IN_PROGRESS);
      }
    };

    handleUpdateStatus();
  }, [manifestEntry?.status?.id]);

  const approved = manifestData?.data?.result?.manifestEntries?.length > 0 && manifestData?.data?.result?.manifestEntries?.filter((x) => x?.status?.id === MANIFEST_ENTRY_STATUS.APPROVED)?.length;
  const inProgress = manifestData?.data?.result?.manifestEntries?.length > 0 && manifestData?.data?.result?.manifestEntries?.filter((x) => x?.status?.id === MANIFEST_ENTRY_STATUS.IN_PROGRESS)?.length;
  const denied = manifestData?.data?.result?.manifestEntries?.length > 0 && manifestData?.data?.result?.manifestEntries?.filter((x) => x?.status?.id === MANIFEST_ENTRY_STATUS.DENIED)?.length;
  const flagged = manifestData?.data?.result?.manifestEntries?.length > 0 && manifestData?.data?.result?.manifestEntries?.filter((x) => x?.status?.id === MANIFEST_ENTRY_STATUS.FLAGGED)?.length;
  const total = manifestData?.data?.result?.manifestEntries?.length;

  const completed = approved + denied + flagged;
  const completedPercent = (completed / total) * 100;

  return (
    <>
      <Container disableGutters maxWidth={false}>
        <OverlayLoader isLoading={manifestLoading || updateEntryStatus.isLoading || manifestEntryLoading} />
        <Grid container sx={{ height: "calc(100VH - 64px)" }}>
          <Grid item xs={9} px={4} pt={4} pb={1} sx={{ backgroundColor: "white" }}>
            <Box>
              <Button size="large" startIcon={<ChevronLeft />} variant="text" color="primary" component={Link} to={`/manifests/${id}`}>
                Back
              </Button>
              <Typography variant="h4" color="text.primary">
                Final Review: Manifest {manifest?.name}
              </Typography>
              <Typography variant="h4" color="text.primary">
                (STOCK # {manifestEntry?.stockNumber})
              </Typography>
            </Box>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box>
                  <Typography variant="h4" color="text.primary" sx={{ mt: 2 }}>
                    {manifestEntry?.vehicle?.year} {manifestEntry?.vehicle?.make} {manifestEntry?.vehicle?.model} {manifestEntry?.vehicle?.trim} {manifestEntry?.vehicle?.engineLiters}L {manifestEntry?.vehicle?.engine}
                  </Typography>
                </Box>
                <Box>
                  <Chip label={`${completed}/${total}`} icon={<Numbers />} sx={{ mr: 1 }} />
                  <Chip label={approved} icon={<CheckCircle />} color="success" sx={{ mr: 1 }} />
                  <Chip label={flagged} icon={<FlagCircle />} color="warning" sx={{ mr: 1 }} />
                  <Chip label={denied} icon={<Close />} color="error" sx={{ mr: 1 }} />
                  <Chip label={inProgress} icon={<Pending />} color="info" sx={{ mr: 1 }} />
                  <Chip label={completedPercent?.toFixed(0)} icon={<Percent />} color="primary" sx={{ mr: 1 }} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"row"} justifyContent={"flex-end"} alignItems="center">
              <ClickAwayListener onClickAway={() => setCopiedTooltip(false)}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    placement="left"
                    onClose={() => setCopiedTooltip(false)}
                    open={copiedTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Copied!">
                    <IconButton size="small" sx={{ height: "24px", width: "24px", p: 0, mr: 1 }} onClick={() => handleVinCopy()} disabled={!manifestEntry?.vin}>
                      <ContentCopyIcon fontSize="inherit" sx={{ color: theme.palette.primary.dark }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>

              <Typography textAlign="end" sx={{ fontSize: 24, letterSpacing: "0.16px", fontWeight: 600, mr: "4px" }}>
                VIN:
              </Typography>
              <Typography textAlign="end" sx={{ fontSize: 24, letterSpacing: "0.15px" }}>
                {manifestEntry?.vin ?? "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SubmissionTable manifestEntry={manifestEntry} manifest={manifest} />
            </Grid>
            <Grid item container xs={8} sx={{ backgroundColor: "primary.main", p: 5, pt: 7, position: "absolute", bottom: 0, borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
              <Box sx={{ flexGrow: 1, display: "flex", gap: 6 }}>
                <Button fullWidth startIcon={<ChevronLeft />} size="large" variant="outlined" color="secondary" onClick={() => handlePrevious()}>
                  Previous
                </Button>
                <Button disabled={!enableApprove} fullWidth startIcon={manifestEntry?.status?.id === MANIFEST_ENTRY_STATUS.APPROVED && <CheckCircle />} size="large" variant="contained" color="success" onClick={() => handleUpdateEntryStatus(MANIFEST_ENTRY_STATUS.APPROVED)}>
                  Approve
                </Button>
                <Button fullWidth startIcon={manifestEntry?.status?.id === MANIFEST_ENTRY_STATUS.DENIED && <Close />} size="large" variant="contained" color="error" onClick={() => handleUpdateEntryStatus(MANIFEST_ENTRY_STATUS.DENIED)}>
                  Deny
                </Button>
                <Button fullWidth endIcon={<ChevronRight />} size="large" variant="outlined" color="secondary" onClick={() => handleNext()}>
                  Next
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={3} sx={{ backgroundColor: theme.palette.secondary.main }}>
            <TabContext value={tabValue}>
              <Grid container px={2} pt={4}>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Motor Data" value="1" />
                    <Tab label="Ap Exhaust" value="2" />
                  </TabList>
                </Grid>
              </Grid>
              <TabPanel value="1" sx={{ maxHeight: "calc(100VH - 200px)", overflowY: "auto" }}>
                <Grid container spacing={2}>
                  {groupedMotoData?.length > 0 ? (
                    groupedMotoData.map((image, index) => (
                      <Grid item xs={12} key={index}>
                        <Card sx={{ padding: 2, position: "relative" }} title={image?.descriptionMatch}>
                          <Box sx={{ position: "absolute" }}>
                            <Typography variant="h6">{image?.positions?.join(", ")}</Typography>
                          </Box>
                          <Box component="img" src={image.diagramUrl} sx={{ mt: 2, width: "100%", height: "100%", objectFit: "cover" }} onClick={() => handleImageClick(image)} />
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="h6">No Diagrams Available</Typography>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value="2" sx={{ maxHeight: "calc(100VH - 200px)", overflowY: "auto" }}>
                <Grid container spacing={2}>
                  {apDataDiagrams?.length > 0 ? (
                    apDataDiagrams.map((image, index) => (
                      <Grid item xs={12} key={index}>
                        <Card sx={{ padding: 2 }} title={image?.descriptionMatch}>
                          <Box component="img" src={image.diagramUrl} sx={{ width: "100%", height: "100%", objectFit: "cover" }} onClick={() => handleImageClick(image)} />
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="h6">No Diagrams Available</Typography>
                  )}
                </Grid>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Container>
      <Dialog open={Boolean(selectedImage)} fullWidth maxWidth="xl" onClose={handleCloseDialog}>
        <DialogTitle>Diagram {selectedImage?.positions?.length > 0 && selectedImage?.positions?.join(", ")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img src={selectedImage?.diagramUrl} alt="Full Screen" style={{ width: "100%", height: "auto" }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
