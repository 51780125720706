import { ChevronRight } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, Chip, CircularProgress, Divider, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { MANIFEST_STATUS, QUERIES } from "../utils/constants";
import { useQuery } from "react-query";
import manifestService from "../services/manifestService";
import Moment from "react-moment";

export const ManifestsOverviewCard = () => {
  const { data: manifests, isLoading } = useQuery([QUERIES.Manifests, "simple"], () => manifestService.getSimple());

  const getManifestReviewStatus = (reviewStatusResultId) => {
    if (reviewStatusResultId === MANIFEST_STATUS.COMPLETED) {
      return <Chip label="Completed" color="success" sx={{ width: 100 }} />;
    } else if (reviewStatusResultId === MANIFEST_STATUS.IN_PROGRESS) {
      return <Chip label="In Progress" color="info" sx={{ width: 100 }} />;
    } else if (reviewStatusResultId === MANIFEST_STATUS.IN_REVIEW) {
      return <Chip label="In Review" color="primary" sx={{ width: 100 }} />;
    } else {
      return <Chip label="Draft" color="default" sx={{ width: 100 }} />;
    }
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" color="text.primary">
            Manifests
          </Typography>
        }
        action={
          <Button endIcon={<ChevronRight />} variant="outlined" component={Link} to={`/manifests`}>
            View
          </Button>
        }
      />
      <CardContent>
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            }}>
            <CircularProgress />
          </Box>
        )}
        {manifests?.data?.result && (
          <List>
            {manifests?.data?.result?.slice(0, 4).map((manifest) => (
              <>
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" component={Link} to={`/manifests/${manifest.id}`}>
                      <ChevronRight />
                    </IconButton>
                  }>
                  <ListItemText primary={manifest.name} primaryTypographyProps={{ sx: { fontSize: 20, fontWeight: 700, lineHeight: "32px", letterSpacing: "0.15px" } }} secondary={<Moment format="LLL">{manifest?.created}</Moment>} />
                  {getManifestReviewStatus(manifest.status.id)}
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};
