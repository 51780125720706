import Button from "@mui/material/Button";
import { Box, Card, CardContent, CardHeader, Container, Grid, Link, TextField, Typography, useTheme } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { IMAGES } from "../utils/constants";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { auth } from "../utils/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  email: yup.string().email("Enter a valid email").required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await signInWithEmailAndPassword(auth, values.email, values.password);

        navigate("/");
      } catch (error) {
        formik.setErrors({ email: "Invalid email or password", password: "Invalid email or password" });
      }
    },
  });
  
  return (
    <Container maxWidth={false} disableGutters>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ display: { xs: "none", sm: "flex" }, backgroundColor: theme.palette.primary.main, height: "100VH", justifyContent: "center", alignItems: "center" }}>
          <Box component="img" src={IMAGES.LOGO_LIGHT} alt="the-control-center" sx={{ maxWidth: "80%" }} />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ backgroundImage: `url(${IMAGES.LOGIN_BG})`, height: "100VH", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Grid container justifyContent="center" alignItems="center" spacing={1}>
            <Card>
              <CardHeader title="Sign In" subheader="Enter your Control Center credentials below to sign in" />
              <CardContent>
                <form onSubmit={formik.handleSubmit}>
                  <TextField fullWidth id="email" name="email" label="Email Address" value={formik.values.email} onChange={formik.handleChange} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} margin="normal" />
                  <TextField fullWidth id="password" name="password" label="Password" type="password" value={formik.values.password} onChange={formik.handleChange} error={formik.touched.password && Boolean(formik.errors.password)} helperText={formik.touched.password && formik.errors.password} margin="normal" />
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 1 }}>
                    <Link component={RouterLink} underline="none" to="/forgot-password" variant="body2">
                      Forgot Password?
                    </Link>
                  </Box>
                  <Button sx={{ mt: 3 }} size="large" color="primary" variant="contained" fullWidth type="submit" disabled={formik.isSubmitting}>
                    SIGN IN
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
