import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography, TextField, DialogActions, styled, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { Close } from "@mui/icons-material";
import { useMutation } from "react-query";
import { UploadFile } from "@mui/icons-material";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import manifestService from "../services/manifestService";
import { enqueueSnackbar } from "notistack";

const DropzoneContainer = styled("div")(({ theme, isDragActive, isInvalidFileType }) => ({
  border: `2px dashed ${isInvalidFileType ? theme.palette.error.main : isDragActive ? theme.palette.primary.main : theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  textAlign: "center",
  cursor: "pointer",
  outline: "none",

  transition: "border .24s ease-in-out",
  "&:focus": {
    border: `2px dashed ${theme.palette.primary.main}`,
  },
  "&:hover": { border: `2px dashed ${theme.palette.primary.main}` },

  height: 152,
}));

const UploadIcon = styled(UploadFile)(({ theme, isInvalidFileType }) => ({
  height: "40px",
  color: isInvalidFileType ? theme.palette.error.main : theme.palette.primary.main,
}));

export const ManifestCreateDialog = ({ open, handleClose, onSuccessCallback }) => {
  const [manifestName, setManifestName] = useState(""); // State for manifest name input
  const [csvFile, setCsvFile] = useState();

  const onDrop = useCallback(
    (acceptedFiles) => {
      setCsvFile(acceptedFiles[0]);
    },
    [csvFile],
  );

  const removeFile = () => {
    setCsvFile(null);
  };
  const handleManifestNameChange = (event) => {
    setManifestName(event.target.value);
  };

  const createManifest = useMutation((values) => manifestService.create(values), {
    onSuccess: ({ data }) => {
      let fileData = new FormData();
      fileData.append("file", csvFile);
      uploadCsv.mutate({ id: data.result.id, csv: fileData });
    },
    onError: (error) => {
      enqueueSnackbar("Error created manifest. Please try again.", { variant: "error" });
    },
  });

  const uploadCsv = useMutation((values) => manifestService.uploadCsv(values.id, values.csv), {
    onSuccess: () => {
      onSuccessCallback();
    },
    onError: (error) => {
      enqueueSnackbar("Error created manifest. Please try again.", { variant: "error" });
    },
  });

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    disabled: createManifest.isLoading || uploadCsv.isLoading,
    accept: { "text/csv": [".csv"] },
    maxFiles: 1,
  });

  const handleManifestCreate = async () => {
    await createManifest.mutateAsync({ name: manifestName });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="h4"> Add New Manifest</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleClose} style={{ position: "absolute", top: 24, right: 20 }} size="large" color="inherit">
              <Close sx={{ width: 48, height: 48 }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ mt: 4, px: 5 }}>
        <Grid container sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField label="Manifest Name" variant="outlined" fullWidth value={manifestName} onChange={handleManifestNameChange} />
          </Grid>
          <Grid item xs={12} sx={{ mt: 7 }}>
            <DropzoneContainer {...getRootProps()} isDragActive={isDragActive} isInvalidFileType={isDragReject}>
              <input {...getInputProps()} />
              <UploadIcon isInvalidFileType={isDragReject} />
              <Typography variant="subtitle1" color={isDragReject ? "error" : "primary"}>
                {isDragReject ? "Invalid file type. Only CSV files are allowed." : isDragActive ? "Drop the CSV file here..." : "Upload CSV"}
              </Typography>
            </DropzoneContainer>
          </Grid>
        </Grid>
        {csvFile && (
          <Grid item container xs={12} sx={{ mt: 4, pl: 4, display: "flex", flexDirection: "row" }}>
            <UploadFile sx={{ height: 40, mr: 3 }} color="primary" />
            <Grid item display="flex" flex={1}>
              <Box flex={1}>
                <Typography fontSize={16} fontWeight={400} lineHeight="28px" letterSpacing={0.15}>
                  {csvFile.path}
                </Typography>
                <Typography color={"textSecondary"} fontSize={14} lineHeight="143%" letterSpacing={0.17}>
                  {Math.round(csvFile.size / 1024)}kb
                </Typography>
              </Box>
              <Box justifyContent={"flex-end"}>
                <IconButton size="small" onClick={removeFile}>
                  <Close />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions sx={{ pb: 5, px: 5 }}>
        <LoadingButton size="large" onClick={handleManifestCreate} variant="contained" color="primary" loading={createManifest.isLoading || uploadCsv.isLoading} disabled={createManifest.isLoading || uploadCsv.isLoading || !csvFile || !manifestName}>
          CREATE NEW MANIFEST
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
