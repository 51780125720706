import { ChevronRight } from "@mui/icons-material";
import { Card, CardContent, CardHeader, Container, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import UserService from "../services/UserService";
import { ApprovedVehiclesCard } from "../ui-components/ApprovedVehiclesCard";
import { CatalyticConvertersCard } from "../ui-components/CatalyticConvertersCard";
import { ManifestsOverviewCard } from "../ui-components/ManifestsOverviewCard";
import { MetalPricesRow } from "../ui-components/MetalPricesRow";
import { useQuery } from "react-query";
import pgmPricesService from "../services/pgmPricesService";
import { QUERIES } from "../utils/constants";
import OverlayLoader from "../ui-components/OverlayLoader";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";

export const Home = () => {
  const { data: pgmPrices, isFetching: pgmPricesLoading } = useQuery([QUERIES.PgmPrices], () => pgmPricesService.getAll());
  const { data: latestPgmPrices, isFetching: latestPgmPricesLoading } = useQuery([QUERIES.PgmPrices, "latest"], () => pgmPricesService.getLatest());

  useEffect(() => {
    UserService.getMe().then((res) => {});
  }, []);

  const isLoading = pgmPricesLoading || latestPgmPricesLoading;

  return (
    <>
      <OverlayLoader isLoading={isLoading} />
      <Container maxWidth="xl">
        <Grid container maxWidth="xl" spacing={5} pt={5}>
          <Grid item md={6} xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <ManifestsOverviewCard />
              </Grid>
              <Grid item xs={12}>
                <CatalyticConvertersCard />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <ApprovedVehiclesCard />
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title={
                      <Typography variant="h4" color="text.primary">
                        Metal Prices
                      </Typography>
                    }
                    action={
                      <Button LinkComponent={Link} to="/pgm-prices" endIcon={<ChevronRight />} variant="outlined">
                        View
                      </Button>
                    }
                  />
                  <CardContent>
                    <Grid container pt={2} px={2}>
                      <MetalPricesRow metalName="Platinum" metalPrice={<CurrencyFormat value={latestPgmPrices?.data?.result?.platinumPrice} displayType={"text"} thousandSeparator={true} prefix={"$"} decimalScale={0} fixedDecimalScale={true} />} metalDifference={+0.5} />
                      <MetalPricesRow metalName="Palladium" metalPrice={<CurrencyFormat value={latestPgmPrices?.data?.result?.palladiumPrice} displayType={"text"} thousandSeparator={true} prefix={"$"} decimalScale={0} fixedDecimalScale={true} />} metalDifference={-0.5} />
                      <MetalPricesRow metalName="Rhodium" metalPrice={<CurrencyFormat value={latestPgmPrices?.data?.result?.rhodiumPrice} displayType={"text"} thousandSeparator={true} prefix={"$"} decimalScale={0} fixedDecimalScale={true} />} metalDifference={-1.5} />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
