import React, { useEffect, useState, createContext } from "react";
import { auth } from "../utils/firebase";
import { signOut } from "firebase/auth";
import { Box, Fade, useTheme } from "@mui/material";
import { IMAGES } from "../utils/constants";
import Hotjar from "@hotjar/browser";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const theme = useTheme();

  const [currentUser, setCurrentUser] = useState(null);
  const [currentClaims, setCurrentClaims] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      Hotjar.identify(user?.uid, {
        email: user?.email,
      });

      setCurrentUser(user);

      if (user) {
        user.getIdTokenResult().then((idToken) => {
          setCurrentClaims(idToken?.claims);
        });
      } else {
        setCurrentClaims(null);
      }

      setTimeout(() => {
        setLoading(false);
      }, 1500); // 1000 milliseconds = 1 second
    });

    return unsubscribe;
  }, []);

  const logOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Sign out error:", error.message);
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, currentClaims, logOut }}>
      <Fade in={loading} timeout={1300}>
        <Box
          sx={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Box component={"img"} src={IMAGES.LOGO_LIGHT} sx={{ maxHeight: 150, maxWidth: "80%" }} />
        </Box>
      </Fade>

      {!loading && children}
    </AuthContext.Provider>
  );
};
