import { Chip, Grid, Typography } from "@mui/material";
import { TrendingDown, TrendingUp } from "@mui/icons-material";
import { Line } from "react-chartjs-2";

export const MetalPricesRow = ({ metalName, metalPrice, metalDifference }) => {
  const gradientStroke = document.createElement("canvas").getContext("2d").createLinearGradient(0, 0, 0, 200);

  var metalIcon = <TrendingUp color="success" />;
  var borderColor = "#36F097";

  if (metalDifference > 0) {
    gradientStroke.addColorStop(0, "#36F097");
    gradientStroke.addColorStop(0.3007, "rgba(136, 219, 179, 0.375)");
    gradientStroke.addColorStop(1, "rgba(217, 217, 217, 0)");
  } else {
    gradientStroke.addColorStop(0, "#FF5252");
    gradientStroke.addColorStop(0.3007, "rgba(255, 82, 82, 0.375)");
    gradientStroke.addColorStop(1, "rgba(217, 217, 217, 0)");

    metalIcon = <TrendingDown color="error" />;
    borderColor = "#FF5252";
  }

  const lineLabels = Array.from({ length: 30 }, (_, i) => i);
  const lineData = {
    labels: lineLabels,
    datasets: [
      {
        fill: true,
        label: "Rhodium",
        data: lineLabels.map(() => Math.round(Math.random() * 5 + 4)),
        borderColor: borderColor,
        backgroundColor: gradientStroke,
      },
    ],
  };

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        borderWidth: 1,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        tension: 0, // Set tension to 0 for a straight line
      },
    },
  };

  return (
    <Grid item container spacing={2} pb={2} xs={12} mb={3} sx={{ alignItems: "center", border: "1px solid rgba(197, 202, 233, 0.4)", borderRadius: 2 }}>
      <Grid item xs={3}>
        <Typography variant="h6" color="text.primary">
          {metalName}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Line options={lineOptions} data={lineData} height={200} />
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h5" color="text.primary" textAlign="right">
          {metalPrice}
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right" pr={2}>
        <Chip icon={metalIcon} label={`${metalDifference}%`} color="default" sx={{ width: 100 }} />
      </Grid>
    </Grid>
  );
};
