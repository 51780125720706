import React, { useContext, useEffect, useRef, useState } from "react";
import MuiDrawer from "@mui/material/Drawer";
import { ExitToApp, Menu, Home, FactCheck, DirectionsCar, AttachMoney, Person, Search, Key } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { Link, useMediaQuery, ListItem, Toolbar, Typography, IconButton, ListItemButton, ListItemIcon, ListItemText, styled, useTheme, Box, List, AppBar } from "@mui/material";
import { useLocation } from "react-router-dom";
import { IMAGES } from "../utils/constants";
import { AuthContext } from "../contexts/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../utils/firebase";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  [`& .MuiDrawer-paper`]: {
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const NavBar = ({ showDrawer = true }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { currentUser, currentClaims } = useContext(AuthContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen size is small
  const location = useLocation();
  const appBarRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (appBarRef.current && !appBarRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    signOut(auth);
  };

  return (
    <>
      <AppBar
        position="fixed"
        color="default"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          transition: (theme) =>
            theme.transitions.create(["width", "margin"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
        ref={appBarRef}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            {showDrawer && (
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle} sx={{ mr: 4, color: theme.palette.primary.main }}>
                <Menu />
              </IconButton>
            )}
            <Link component={RouterLink} to="/" color="inherit" variant="h6">
              <Box component="img" src={IMAGES.LOGO_PRIMARY} sx={{ height: 42, mt: 1 }} />
            </Link>
          </Box>
          {currentUser && (
            <>
              {!isSmallScreen && (
                <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
                  {currentUser?.email}
                </Typography>
              )}
              <IconButton onClick={handleLogout} color="inherit" sx={{ color: theme.palette.primary.main }}>
                <ExitToApp />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
      {showDrawer && (
        <Drawer variant="permanent" open={open}>
          <Toolbar />
          <List
            sx={{
              m: 1,
              mt: 6,
              borderRadius: 2,
              "&& .Mui-selected, && .Mui-selected:hover": {
                backgroundColor: theme.palette.highlight,
              },
              // hover states
              "& .MuiListItemButton-root:hover": {
                backgroundColor: theme.palette.highlight,
              },
            }}>
            <ListItem
              key={"Home"}
              disablePadding
              sx={{
                display: "block",
                color: theme.palette.primary.contrastText,
              }}
              component={RouterLink}
              to="/">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  my: 1,
                  borderRadius: 1,
                }}
                selected={location.pathname === "/"}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: theme.palette.primary.contrastText,
                  }}>
                  <Home />
                </ListItemIcon>
                <ListItemText primary={"Home"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={"Manifests"}
              disablePadding
              sx={{
                display: "block",
                color: theme.palette.primary.contrastText,
              }}
              component={RouterLink}
              to="/manifests">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  borderRadius: 1,
                }}
                selected={location.pathname === "/manifests"}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: theme.palette.primary.contrastText,
                  }}>
                  <FactCheck />
                </ListItemIcon>
                <ListItemText primary={"Manifests"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={"Vehicles"}
              disablePadding
              sx={{
                display: "block",
                color: theme.palette.primary.contrastText,
              }}
              component={RouterLink}
              to="/vehicles">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  borderRadius: 1,
                }}
                selected={location.pathname === "/vehicles"}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: theme.palette.primary.contrastText,
                  }}>
                  <DirectionsCar />
                </ListItemIcon>
                <ListItemText primary={"Vehicles"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={"PGM Prices"}
              disablePadding
              sx={{
                display: "block",
                color: theme.palette.primary.contrastText,
              }}
              component={RouterLink}
              to="/pgm-prices">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  borderRadius: 1,
                }}
                selected={location.pathname === "/pgm-prices"}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: theme.palette.primary.contrastText,
                  }}>
                  <AttachMoney />
                </ListItemIcon>
                <ListItemText primary={"PGM Prices"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={"Users"}
              disablePadding
              sx={{
                display: "block",
                color: theme.palette.primary.contrastText,
              }}
              component={RouterLink}
              to="/users">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  borderRadius: 1,
                }}
                selected={location.pathname === "/users"}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: theme.palette.primary.contrastText,
                  }}>
                  <Person />
                </ListItemIcon>
                <ListItemText primary={"Users"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={"Converters"}
              disablePadding
              sx={{
                display: "block",
                color: theme.palette.primary.contrastText,
              }}
              component={RouterLink}
              to="/converters">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  borderRadius: 1,
                }}
                selected={location.pathname === "/converters"}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: theme.palette.primary.contrastText,
                  }}>
                  <Search />
                </ListItemIcon>
                <ListItemText primary={"Converters"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            {currentClaims?.cc_apiKeys && (
              <ListItem
                key={"ApiKeys"}
                disablePadding
                sx={{
                  display: "block",
                  color: theme.palette.primary.contrastText,
                }}
                component={RouterLink}
                to="/api-keys">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    borderRadius: 1,
                  }}
                  selected={location.pathname === "/api-keys"}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: theme.palette.primary.contrastText,
                    }}>
                    <Key />
                  </ListItemIcon>
                  <ListItemText primary={"API Key Management"} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Drawer>
      )}
    </>
  );
};

export default NavBar;
