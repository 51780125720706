import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Moment from "react-moment";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { MANIFEST_ENTRY_STATUS } from "../utils/constants";
import { Edit } from "@mui/icons-material";

const ManifestOverviewCard = ({ manifest }) => {
  var chipColor = "primary";

  if (manifest.status.name === "In Review") {
    chipColor = "primary";
  } else if (manifest.status.name === "Draft") {
    chipColor = "default";
  } else if (manifest.status.name === "In Progress") {
    chipColor = "info";
  }

  const getReviewedCount = () => {
    if (manifest.manifestEntries?.length > 0) {
      return `${manifest.manifestEntries?.filter((x) => x.status.id !== MANIFEST_ENTRY_STATUS.DRAFT && x.status.id !== MANIFEST_ENTRY_STATUS.IN_PROGRESS).length} / ${manifest.manifestEntries?.length}`;
    } else {
      return 0;
    }
  };

  const getApprovedCount = () => {
    if (manifest.manifestEntries?.length > 0) {
      return `${manifest.manifestEntries?.filter((x) => x.status.id === MANIFEST_ENTRY_STATUS.APPROVED).length} / ${manifest.manifestEntries?.length}`;
    } else {
      return 0;
    }
  };

  const getFlaggedCount = () => {
    if (manifest.manifestEntries?.length > 0) {
      return `${manifest.manifestEntries?.filter((x) => x.status.id === MANIFEST_ENTRY_STATUS.FLAGGED).length} / ${manifest.manifestEntries?.length}`;
    } else {
      return 0;
    }
  };

  const getDeniedCount = () => {
    if (manifest.manifestEntries?.length > 0) {
      return `${manifest.manifestEntries?.filter((x) => x.status.id === MANIFEST_ENTRY_STATUS.DENIED).length} / ${manifest.manifestEntries?.length}`;
    } else {
      return 0;
    }
  };

  const getCompletedPercentage = () => {
    if (manifest.manifestEntries?.length > 0) {
      let percentage = Math.round((manifest.manifestEntries?.filter((x) => x.status.id === MANIFEST_ENTRY_STATUS.APPROVED || x.status.id === MANIFEST_ENTRY_STATUS.DENIED).length / manifest.manifestEntries?.length) * 100);
      return `${percentage}%`;
    } else {
      return "0%";
    }
  };

  const cardRow = (label, value, divider) => {
    return (
      <>
        <Box sx={{ display: "flex", justifyContent: "space-between", py: 1 }}>
          <Typography variant="body2" color="text.primary" sx={{ fontWeight: 700 }}>
            {label}
          </Typography>
          <Typography variant="body1">{value}</Typography>
        </Box>
        {divider && <Divider sx={{ mb: 1 }} />}
      </>
    );
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Chip label={manifest.status.name} color={chipColor} />
          </Box>
          <Box sx={{ textAlign: "right" }}>
            <Typography variant="body1" color="primary.main">
              <Moment format="hh:mm A" utc local>
                {manifest?.created}
              </Moment>
            </Typography>
            <Typography variant="body1" color="primary.main">
              <Moment format="MMM DD, YYYY" utc local>
                {manifest?.created}
              </Moment>
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h4" color="text.primary">
            {manifest?.name}
          </Typography>
        </Box>
        {cardRow("Stock", manifest.manifestEntries?.length ?? 0, true)}
        {cardRow("Vehicles", manifest.manifestEntries?.filter((x) => x.vehicleId !== null).length ?? 0, true)}
        {cardRow("Reviewed", getReviewedCount(), true)}
        {cardRow("Approved", getApprovedCount(), false)}
        {cardRow("Flagged", getFlaggedCount(), false)}
        {cardRow("Denied", getDeniedCount(), false)}
        {cardRow("Completed", getCompletedPercentage(), false)}
        <Box mt={4}>
          <Button size="large" fullWidth variant="contained" color="primary" component={Link} to={`/manifests/${manifest.id}`}>
            OPEN MANIFEST
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ManifestOverviewCard;
