import api from "../utils/axios-client";

const getMe = async () => api.get("/v1/users/me");

const get = async () => api.get(`/v1/users/admin`);

const createUser = async (user) => api.post(`/v1/users/admin`, user);

const updateUserPermissions = async (userId, user) => api.put(`/v1/users/admin/${userId}/permissions`, user);

export default { getMe, get, createUser, updateUserPermissions };
