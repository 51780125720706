import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardContent, CardHeader, Chip, Grid, Typography, Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, TextField, Drawer } from "@mui/material";
import { CheckCircle, ChevronLeft, Close, Error, FlagCircle } from "@mui/icons-material";
import { useQuery } from "react-query";
import manifestService from "../services/manifestService";
import { QUERIES, MANIFEST_ENTRY_STATUS } from "../utils/constants";
import OverlayLoader from "../ui-components/OverlayLoader";
import Moment from "react-moment";
import Zoom from "react-medium-image-zoom";

import { ManifestEntryAnalyzeDialog } from "../ui-components/ManifestEntryAnalyzeDialog";
import vehicleService from "../services/vehicleService";
import CurrencyFormat from "react-currency-format";
import { DataGrid } from "@mui/x-data-grid";

const initalFilter = {
  search: null,
  manifestStatuses: [],
  manifestEntryStatuses: [],
  manifests: [],
  makes: [],
  models: [],
  years: [],
  engines: [],
  size: 25,
  enabled: false,
};

export const Vehicles = () => {
  const [filter, setFilter] = useState(initalFilter);
  const [selectedManifestEntry, setSelectedManifestEntry] = useState(null);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const { data: vehicleYears, isFetching: vehicleYearsLoading } = useQuery([QUERIES.VehicleYears], () => vehicleService.getYears());
  const { data: vehicleMakes, isFetching: vehicleMakesLoading } = useQuery([QUERIES.VehicleMakes, filter.years], () => vehicleService.getMakes(filter.years));
  const { data: vehicleModels, isFetching: vehicleModelsLoading } = useQuery([QUERIES.VehicleModels, filter.years, filter.makes], () => vehicleService.getModels(filter.years, filter.makes));
  const { data: vehicleEngines, isFetching: vehicleEnginesLoading } = useQuery([QUERIES.VehicleEngines, filter.years, filter.makes, filter.models], () => vehicleService.getEngines(filter.years, filter.makes, filter.models));
  const { data: manifests, isFetching: manifestsLoading } = useQuery([QUERIES.Manifests, "simple"], () => manifestService.getSimple());
  const {
    data: manifestEntryVehicles,
    isFetching: vehiclesLoading,
    refetch,
    isFetched,
  } = useQuery(
    [QUERIES.ManifestEntryVehicles],
    () => {
      var test = {
        search: filter.search,
        manifestStatusIds: filter.manifestStatuses.map((x) => x.id),
        manifestEntryStatusIds: filter.manifestEntryStatuses.map((x) => x.id),
        manifestIds: filter.manifests.map((x) => x.id),
        makes: filter.makes,
        models: filter.models,
        engines: filter.engines,
        years: filter.years,
        size: filter.size,
      };
      return manifestService.getEntryVehicles(test);
    },
    {
      enabled: false,
    },
  );
  const { data: manifestStatuses, isFetching: manifestStatusLoading } = useQuery([QUERIES.ManifestStatus], () => manifestService.getManifestStatuses());
  const { data: manifestEntryStatuses, isFetching: manifestEntryStatusesLoading } = useQuery([QUERIES.ManifestEntryStatus], () => manifestService.getManifestEntryStatuses());

  useEffect(() => {
    if (manifestEntryStatuses?.data?.result && filter.enabled === false) {
      setFilter({
        ...filter,
        manifestEntryStatuses: manifestEntryStatuses?.data?.result?.filter((x) => x.id === MANIFEST_ENTRY_STATUS.APPROVED),
        enabled: true,
      });
    }
  }, [manifestEntryStatuses?.data?.result]);

  useEffect(() => {
    if (filter.enabled && !isFetched) {
      refetch();
    }
  }, [filter]);

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const isEmpty = (obj) => {
    return obj == null || obj.length === 0;
  };

  const handleYearChange = (event) => {
    const {
      target: { value },
    } = event;

    setFilter({
      ...filter,
      years: value,
    });
  };

  const handleMakesChange = (event) => {
    const {
      target: { value },
    } = event;

    setFilter({
      ...filter,
      makes: value,
    });
  };

  const handleManifestsChange = (event) => {
    const {
      target: { value },
    } = event;

    setFilter({
      ...filter,
      manifests: value,
    });
  };

  const handleModelsChange = (event) => {
    const {
      target: { value },
    } = event;

    setFilter({
      ...filter,
      models: value,
    });
  };

  const handleEnginesChange = (event) => {
    const {
      target: { value },
    } = event;

    setFilter({
      ...filter,
      engines: value,
    });
  };

  const handleStatusChange = (event) => {
    const {
      target: { value },
    } = event;

    setFilter({
      ...filter,
      manifestStatuses: value,
    });
  };

  const handleEntryStatusChange = (event) => {
    const {
      target: { value },
    } = event;

    setFilter({
      ...filter,
      manifestEntryStatuses: value,
    });
  };

  const handleSizeChange = (event) => {
    const {
      target: { value },
    } = event;

    setFilter({
      ...filter,
      size: value,
    });
  };

  const renderStatusChip = (selected) => {
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {selected.map((value) => (
          <Chip sx={{ mr: 1 }} key={value.id} label={value.name} />
        ))}
      </Box>
    );
  };

  const renderYearChip = (selected) => {
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {selected.map((value) => (
          <Chip sx={{ mr: 1 }} key={value} label={value} />
        ))}
      </Box>
    );
  };

  const renderManifestChip = (selected) => {
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {selected.map((value) => (
          <Chip sx={{ mr: 1 }} key={value.id} label={value.name} />
        ))}
      </Box>
    );
  };

  const renderEngineChip = (selected) => {
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {selected.map((value) => (
          <Chip sx={{ mr: 1 }} key={value} label={`${value} L`} />
        ))}
      </Box>
    );
  };

  const isLoading = manifestsLoading || vehiclesLoading || manifestStatusLoading || manifestEntryStatusesLoading || vehicleYearsLoading || vehicleMakesLoading || vehicleModelsLoading || vehicleEnginesLoading;
  const vehicles = manifestEntryVehicles?.data?.result;

  const columns = [
    {
      headerName: "Status",
      field: "status.id",
      width: 200,
      sortable: false,
      renderCell: (params) => {
        const { row } = params;
        var chipStyle = "primary";
        var chipIcon = null;

        if (row?.status?.id === MANIFEST_ENTRY_STATUS.APPROVED) {
          chipStyle = "success";
          chipIcon = <CheckCircle />;
        } else if (row?.status?.id === MANIFEST_ENTRY_STATUS.FLAGGED) {
          chipStyle = "warning";
          chipIcon = <FlagCircle />;
        } else if (row?.status?.id === MANIFEST_ENTRY_STATUS.DENIED) {
          chipStyle = "error";
          chipIcon = <Error />;
        }

        return (
          <Box sx={{ flexDirection: "column", display: "flex", gap: 1, py: 2 }}>
            <Box>
              <Chip icon={chipIcon} color={chipStyle} label={row?.status?.name} variant="outlined" />
            </Box>
            <Typography
              as={Link}
              sx={{
                fontSize: 16,
              }}
              color="text.primary"
              target="_blank"
              to={`/manifests/${row?.manifest?.id}/review/${row?.id}`}
              variant="subtitle1">
              {row?.manifest?.name}
            </Typography>
            <Box>
              <Typography variant="subtitle1">{row?.statusChangedByUser?.name ?? "--"}</Typography>
              <Typography variant="body2">
                <Moment format="MM/DD/yyyy hh:mm A" utc local>
                  {row?.lastUpdated}
                </Moment>
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      headerName: "Vehicle",
      width: 300,
      sortable: false,
      renderCell: (params) => {
        const { row } = params; // Assuming 'status' is the correct field in your data

        return (
          <Box
            onClick={() => setSelectedManifestEntry(row)}
            sx={{
              fontSize: 16,
              textDecoration: "underline",
              cursor: "pointer",
            }}>
            <Typography variant="subtitle1">{isEmpty(row.vehicle?.make) ? "--" : `${row.vehicle?.year} ${row.vehicle?.make} ${row.vehicle?.model} ${row.vehicle.engineLiters}L`}</Typography>
            <Typography mt={1} variant="subtitle1">
              {row.vin}
            </Typography>
          </Box>
        );
      },
    },
    {
      headerName: "Total Price",
      field: "totalPrice",
      minWidth: 150,
      renderCell: (params) => {
        const { row } = params;

        return (
          <Box pr={2}>
            <Typography variant="body2">
              <CurrencyFormat value={row?.totalPrice} displayType="text" thousandSeparator={true} fixedDecimalScale decimalScale={2} prefix={"$"} />
            </Typography>
          </Box>
        );
      },
    },
    {
      headerName: "Converters",
      field: "manifestEntryCatalyticConverters",
      minWidth: 800,
      sortable: false,
      renderCell: (params) => {
        const { row } = params; // Assuming 'status' is the correct field in your data

        return (
          <Grid container spacing={3} sx={{ maxWidth: 600 }}>
            {row?.manifestEntryCatalyticConverters
              ?.filter((cat) => cat?.enabled)
              ?.map((submission, index) => {
                let primaryCodeText = submission?.converterConnectionsCatalyticConverter?.code || submission?.catalyticConverterPrimaryCode || "No Code";

                var imageUrls = [];
                if (submission?.frontImage?.thumbnailUrl) imageUrls.push(submission?.frontImage);
                if (submission?.backImage?.thumbnailUrl) imageUrls.push(submission?.backImage);
                if (submission?.codeImage?.thumbnailUrl) imageUrls.push(submission?.codeImage);
                if (submission?.images?.length > 0) imageUrls.push(...submission?.images?.map((image) => image));

                return (
                  <Grid item xs={6} key={`cat-results-${index}`}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        minWidth: 260,
                      }}>
                      <Typography variant="body1">{primaryCodeText}</Typography>
                      <Typography>
                        <CurrencyFormat value={submission?.converterConnectionsCatalyticConverter?.price ?? 0} displayType="text" thousandSeparator={true} fixedDecimalScale decimalScale={2} prefix={"$"} />
                      </Typography>
                    </Box>

                    <Box display="flex" overflow="auto" flexDirection="row" gap={1}>
                      {imageUrls.map((image, index) => {
                        return (
                          <Zoom key={`cat-image-${index}`} zoomImg={{ src: image.url }}>
                            <Box sx={{ maxHeight: 80, maxWidth: 80 }} component="img" src={image.thumbnailUrl} />
                          </Zoom>
                        );
                      })}
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        );
      },
    },
  ];

  return (
    <>
      <OverlayLoader isLoading={isLoading} />
      <Drawer
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        anchor="right"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}>
        <Box sx={{ width: 300, p: 3 }} role="presentation">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button sx={{ mb: 2 }} size="large" startIcon={<Close />} variant="text" color="primary" onClick={() => toggleDrawer(false)}>
                CLOSE
              </Button>
            </Grid>
            <Grid item xs={12} pt={2}>
              <FormControl fullWidth>
                <InputLabel id="manifest-entry-label">Vehicle Status</InputLabel>
                <Select labelId="manifest-entry-label" id="manifest-entry-checkbox" multiple value={filter.manifestEntryStatuses} onChange={handleEntryStatusChange} input={<OutlinedInput label="Vehicle Status" />} renderValue={renderStatusChip}>
                  {manifestEntryStatuses?.data?.result &&
                    manifestEntryStatuses?.data?.result
                      ?.sort((a, b) => b?.id - a?.id)
                      ?.map((status) => (
                        <MenuItem key={`status-id-${status?.id}`} value={status}>
                          <Checkbox checked={filter.manifestEntryStatuses.indexOf(status) > -1} />
                          <ListItemText primary={status?.name} />
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="manifest-status-multiple-label">Manifest Status</InputLabel>
                <Select labelId="manifest-status-multiple-label" id="manifest-status-checkbox" multiple value={filter.manifestStatuses} onChange={handleStatusChange} input={<OutlinedInput label="Manifest Status" />} renderValue={renderStatusChip}>
                  {manifestStatuses?.data?.result &&
                    manifestStatuses?.data?.result
                      ?.sort((a, b) => b?.id - a?.id)
                      ?.map((status) => (
                        <MenuItem key={`status-id-${status?.id}`} value={status}>
                          <Checkbox checked={filter.manifestStatuses.indexOf(status) > -1} />
                          <ListItemText primary={status?.name} />
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="years-label">Years</InputLabel>
                <Select labelId="years-label" id="years-checkbox" multiple value={filter.years} onChange={handleYearChange} input={<OutlinedInput label="Years" />} renderValue={renderYearChip}>
                  {vehicleYears?.data?.result &&
                    vehicleYears?.data?.result
                      ?.sort((a, b) => b - a)
                      ?.map((year) => (
                        <MenuItem key={`year-id-${year}`} value={year}>
                          <Checkbox checked={filter.years.indexOf(year) > -1} />
                          <ListItemText primary={year} />
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="makes-label">Makes</InputLabel>
                <Select labelId="makes-label" id="makes-checkbox" multiple value={filter.makes} onChange={handleMakesChange} input={<OutlinedInput label="Makes" />} renderValue={renderYearChip}>
                  {vehicleMakes?.data?.result &&
                    vehicleMakes?.data?.result
                      ?.sort((a, b) => b - a)
                      ?.map((make) => (
                        <MenuItem key={`makes-id-${make}`} value={make}>
                          <Checkbox checked={filter.makes.indexOf(make) > -1} />
                          <ListItemText primary={make} />
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="models-label">Models</InputLabel>
                <Select labelId="models-label" id="models-checkbox" multiple value={filter.models} onChange={handleModelsChange} input={<OutlinedInput label="Models" />} renderValue={renderYearChip}>
                  {vehicleModels?.data?.result &&
                    vehicleModels?.data?.result
                      ?.sort((a, b) => b - a)
                      ?.map((model) => (
                        <MenuItem key={`model-id-${model}`} value={model}>
                          <Checkbox checked={filter.models.indexOf(model) > -1} />
                          <ListItemText primary={model} />
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="engines-label">Engines</InputLabel>
                <Select labelId="engines-label" id="engines-checkbox" multiple value={filter.engines} onChange={handleEnginesChange} input={<OutlinedInput label="Engines" />} renderValue={renderEngineChip}>
                  {vehicleEngines?.data?.result &&
                    vehicleEngines?.data?.result
                      ?.sort((a, b) => b - a)
                      ?.map((engine) => (
                        <MenuItem key={`engines-id-${engine}`} value={engine}>
                          <Checkbox checked={filter.engines.indexOf(engine) > -1} />
                          <ListItemText primary={`${engine} L`} />
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button
                sx={{ mt: 4 }}
                variant="contained"
                size="large"
                fullWidth
                onClick={() => {
                  refetch();
                  toggleDrawer(false);
                }}
                color="primary">
                FILTER
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      <Grid container px={4} pt={2} pb={3} sx={{ backgroundColor: "white" }}>
        <Grid item xs={12}>
          <Button size="large" sx={{ mb: 1 }} startIcon={<ChevronLeft />} variant="text" color="primary" component={Link} to="/manifests">
            Back
          </Button>
          <Typography mt={1} variant="h4" color="text.primary">
            Vehicles Overview
          </Typography>
        </Grid>
        <Grid
          item
          mt={5}
          pl={2}
          xs={12}
          container
          alignItems="center"
          as="form"
          onSubmit={(e) => {
            e.preventDefault();
            refetch();
          }}>
          <Grid item>
            <FormControl sx={{ m: 1 }}>
              <TextField name="manifests-search" id="manifest-search" value={filter.search} onChange={(e) => setFilter({ ...filter, search: e.target.value })} label="Search" />
            </FormControl>
          </Grid>
          <Grid item>
            <Button type="submit" size="large" sx={{ m: 1 }} variant="contained" onClick={() => refetch()} color="primary">
              Search
            </Button>
          </Grid>
          <Grid item>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="manifests-multiple-label">Manifests</InputLabel>
              <Select labelId="manifests-multiple-label" id="manifests-checkbox" multiple value={filter.manifests} onChange={handleManifestsChange} input={<OutlinedInput label="Manifests" />} renderValue={renderManifestChip}>
                {manifests?.data?.result &&
                  manifests?.data?.result
                    ?.sort((a, b) => b?.id - a?.id)
                    ?.map((manifest) => (
                      <MenuItem key={`status-id-${manifest?.id}`} value={manifest}>
                        <Checkbox checked={filter.manifests.indexOf(manifest) > -1} />
                        <ListItemText primary={manifest?.name} />
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button size="large" variant="outlined" color="primary" onClick={() => toggleDrawer(true)}>
              ADVANCED FILTERING
            </Button>
          </Grid>
          <Grid item xs style={{ display: "flex", justifyContent: "flex-end" }}>
            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <InputLabel id="manifest-entry-page-size-label">Results</InputLabel>
              <Select labelId="manifest-entry-page-size-label" id="manifest-entry-page-size" value={filter.size} label="Results" onChange={handleSizeChange}>
                <MenuItem value={1000}>ALL</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container px={4} pt={4}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <>
                  <Typography variant="h5" color="text.primary">
                    Results
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontStyle: "italic",
                    }}>
                    Showing {vehicles?.length} Results
                  </Typography>
                </>
              }
            />
            <CardContent>
              {vehicles && <DataGrid disableColumnFilter disableColumnMenu sx={{ mt: 4 }} getRowHeight={() => "auto"} rows={vehicles} columns={columns} />}
              {vehicles?.length === 0 && (
                <Box display="flex" justifyContent="center" sx={{ mt: 8 }}>
                  <Typography variant="h3">No Vehicles Found</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {selectedManifestEntry != null && <ManifestEntryAnalyzeDialog manifestEntry={selectedManifestEntry} open={selectedManifestEntry != null} handleClose={() => setSelectedManifestEntry(null)} />}
    </>
  );
};
