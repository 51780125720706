import { styled } from "@mui/system";
import NavBar from "./NavBar";
import { Box } from "@mui/material";
import { IMAGES } from "../utils/constants";

const StyledBox = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url(${IMAGES.BG});
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
`;

const CenteredContent = styled(Box)`
  min-height: 100vh;
  padding-top: 64px;
  padding-left: 64px;
`;

export const PageLayout = (props) => {
  return (
    <>
      <NavBar />
      <StyledBox>
        <CenteredContent>{props.children}</CenteredContent>
      </StyledBox>
    </>
  );
};
