import api from "../utils/axios-client";

/**
 * Gets all manifests
 */
const getAll = async (start, end) => api.get("/v1/manifests", { params: { start, end } });

/**
 * Gets a manifest by id
 */
const get = async (id) => api.get(`/v1/manifests/${id}`);

/**
 * Gets a manifests simple dto
 */
const getSimple = async () => api.get(`/v1/manifests/simple`);

// {
//   "search": null,
//   "manifestStatusIds": [],
//   "manifestEntryStatusIds" : [2],
//   "manifestIds" : [],
//   "makes": [],
//   "models": [],
//   "engines": [],
//   "years": []
//   "start": null,
//   "end": null,
// }
const getEntryVehicles = async (searchFilter) => api.post("/v1/manifests/entries/vehicles", searchFilter);

// {
//   "start": null,
//   "end": null,
// }
const getEntryCats = async (searchFilter) => api.post("/v1/manifests/entries/catalytic-converters", searchFilter);

/**
 * Creates a new manifest
 * @param {Object} manifest
 * @param {string} manifest.name
 */

const create = async (manifest) => api.post("/v1/manifests", manifest);

/**
 * Upload a csv file for a manifest to create manifest entries
 * @param {object} csv - the manifest csv
 * @param {object} id - the manifest id
 */
const uploadCsv = async (id, csv) => {
  return api.post(`/v1/manifests/${id}/upload`, csv, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/*
 * Updates the status of a manifest entry
 */
const updateStatus = async (manifestEntryId, statusId) => api.put(`/v1/manifests/entries/${manifestEntryId}/status`, { statusId });

/*
 * Gets a manifest entry by id
 */
const getEntry = async (manifestEntryId) => api.get(`/v1/manifests/entries/${manifestEntryId}`);

/*
 * Attach vehciles to a manifest
 */
const attachVehicles = async (id) => api.post(`/v1/manifests/${id}/attach-vehicles`, { id });

/**
 * Updates a manifest entry catalytic converter
 * @param {number} manifestEntryCatalyticConverterId - the manifest entry catalytic converter id
 * @param {object} data - the data to update
 * @param {string} data.converterConnectionsConverterId - the converter connections catalytic converter id
 */
const updateEntryCat = async (manifestEntryCatalyticConverterId, data) => api.put(`/v1/manifests/entries/catalytic-converter/${manifestEntryCatalyticConverterId}`, data);

/**
 * Migrates a manifest entry catalytic converter to a different manifest entry, in case of wrong stock number selection in the cat lab.
 * @param {number} id - the manifest entry catalytic converter id
 * @param {number} destinationId- the manifest entry id to migrate the converter to
 */
const migrateEntryCat = async (id, destinationid) => api.put(`/v1/manifests/entries/catalytic-converter/${id}/migrate-to-entry/${destinationid}`);

/**
 * Updates a manifest
 * @param {number} manifestId - the manifest id
 * @param {object} data - the data to update
 * @param {string} data.name - the manifest name
 * @param {string} data.statusId - the manifest status id
 */
const updateManifest = async (manifestId, data) => api.put(`/v1/manifests/${manifestId}`, data);

/**
 * Gets all manifest statuses
 */
const getManifestStatuses = async () => api.get("/v1/manifests/statuses");

/**
 * Gets all manifest entry statuses
 */
const getManifestEntryStatuses = async () => api.get("/v1/manifests/entry-statuses");

/**
 * Disables a manifest entry catalytic converter
 * @param {number} id - the manifest entry catalytic converter id
 */
const disableManifestEntryCat = async (id) => api.post(`/v1/manifests/entries/catalytic-converter/${id}/disable`);

export default { getAll, get, updateStatus, create, uploadCsv, getEntry, attachVehicles, updateEntryCat, updateManifest, migrateEntryCat, getEntryVehicles, getManifestStatuses, getManifestEntryStatuses, disableManifestEntryCat, getSimple, getEntryCats };
