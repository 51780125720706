import React, { useState } from "react";
import { Box, Button, DialogContent, DialogTitle, Grid, Typography, TextField, IconButton } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import { Close } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Autocomplete from "@mui/material/Autocomplete";

const ManifestEntryStockChangeStep = ({ manifestEntry, manifest, initialImageIndex, images, onNext, onBack, onClose }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(initialImageIndex);
  const [newManifestEntry, setNewManifestEntry] = useState();

  const incrementImage = () => {
    if (selectedImageIndex + 1 >= images.length) setSelectedImageIndex(0);
    else setSelectedImageIndex(selectedImageIndex + 1);
  };

  const decrementImage = () => {
    if (selectedImageIndex - 1 < 0) setSelectedImageIndex(images.length - 1);
    else setSelectedImageIndex(selectedImageIndex - 1);
  };

  return (
    <>
      <DialogTitle sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={11}>
            <IconButton onClick={() => onBack()} style={{ position: "absolute", top: 24, left: 20 }} size="large" color="primary">
              <ArrowBackIcon sx={{ width: 48, height: 48 }} />
            </IconButton>
            <Typography variant="h4" color="text.primary" mt={6}>
              (STOCK # {manifestEntry?.stockNumber})
            </Typography>
            <Typography variant="h3" color="error">
              INCORRECT STOCK NUMBER
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 4 }}>
            <Typography variant="h5">Select correct stock number</Typography>
            <Autocomplete
              onChange={(event, newValue) => {
                setNewManifestEntry(newValue);
              }}
              sx={{
                mt: 2,
                "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                  fontSize: 32,
                  lineHeight: "24px",
                },
              }}
              options={manifest?.manifestEntries ?? []}
              getOptionLabel={(option) => option.stockNumber}
              renderOption={(props, option) => (
                <Typography
                  {...props}
                  variant="input-text"
                  style={{
                    paddingTop: 32,
                    paddingBottom: 32,
                    fontSize: "32px",
                    lineHeight: "24px",
                  }}>
                  {option.stockNumber}
                </Typography>
              )}
              renderInput={(params) => <TextField {...params} hiddenLabel variant="filled" />}
            />
          </Grid>
          <Grid item container xs={12} display="flex" justifyContent="flex-end" mt={2}>
            <Grid item md={2} xs={12}>
              <Button variant="contained" sx={{ height: 58 }} fullWidth onClick={() => onNext(newManifestEntry)} disabled={!newManifestEntry}>
                NEXT
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={onClose} style={{ position: "absolute", top: 24, right: 20 }} size="large" color="inherit">
              <Close sx={{ width: 48, height: 48 }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ mt: 4, px: 5 }}>
        <Grid container spacing={3}>
          <Grid item justifyContent="center" xs={12}>
            <Carousel showArrows={false} showStatus={false} showThumbs={false} showIndicators={false} selectedItem={selectedImageIndex}>
              {images.map((image) => {
                return <Box sx={{ maxWidth: "40%", cursor: "pointer", margin: "auto", borderRadius: 8 }} component="img" src={image?.url} alt={`Image ${image?.description}`} />;
              })}
            </Carousel>
          </Grid>
          <Grid item xs={12} sx={{ alignItems: "center" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Button onClick={() => decrementImage()} size="large">
                <NavigateBeforeIcon />
              </Button>
              {images.map((image, index) => {
                return (
                  <Button onClick={() => setSelectedImageIndex(index)}>
                    <FiberManualRecordIcon color={selectedImageIndex == index ? "primary" : "disabled"} />
                  </Button>
                );
              })}
              <Button onClick={() => incrementImage()}>
                <NavigateNextIcon />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default ManifestEntryStockChangeStep;
