import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography, DialogActions, IconButton, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useQuery } from "react-query";
import catalyticConverterService from "../services/catalyticConverterService";
import { QUERIES } from "../utils/constants";
import OverlayLoader from "./OverlayLoader";
import { CascadingSearchCard } from "./CascadingSearchCard";
import Scrollbars from "react-custom-scrollbars-2";

export const ManifestEntryAnalyzeDialog = ({ manifestEntry, open, handleClose }) => {
  const { data, isLoading } = useQuery([QUERIES.CatalyticConvertersCascading, manifestEntry?.vin], () => catalyticConverterService.getCascadingSearch([manifestEntry?.vin]), {
    enabled: !!manifestEntry?.vin,
  });

  const firstResult = data?.data?.result && data?.data?.result[0];

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <OverlayLoader isLoading={isLoading} />
      <DialogTitle sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="h5">
              Analyze {firstResult?.vehicle?.year} {firstResult?.vehicle?.make} {firstResult?.vehicle?.model} {firstResult?.vehicle?.engineLiters}L {firstResult?.vin}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleClose} style={{ position: "absolute", top: 24, right: 20 }} size="large" color="inherit">
              <Close sx={{ width: 48, height: 48 }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Scrollbars style={{ height: 800 }}>
        <DialogContent sx={{ px: 5 }}>
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={8}>
              {firstResult?.details &&
                firstResult?.details?.map((cascadeDetails, index) => {
                  return <CascadingSearchCard cascadeDetails={cascadeDetails} key={`cascading-card-${index}`} />;
                })}
            </Grid>
          </Box>
        </DialogContent>
      </Scrollbars>
      <DialogActions sx={{ pb: 5, px: 5 }}>
        <Button size="large" onClick={handleClose} variant="contained" color="primary">
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
