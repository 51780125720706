import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Grid, Tab, Typography, styled } from "@mui/material";
import { useState } from "react";
import { Add } from "@mui/icons-material";
import manifestService from "../services/manifestService";
import { useQuery, useQueryClient } from "react-query";
import OverlayLoader from "../ui-components/OverlayLoader";
import { QUERIES, MANIFEST_STATUS } from "../utils/constants";
import PaginatedCardsView from "../ui-components/PaginatedManifestView";
import { Link } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import { ManifestCreateDialog } from "../ui-components/ManifestCreateDialog";
import { enqueueSnackbar } from "notistack";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

const Label = styled(Typography)({
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "26px",
  letterSpacing: "0.46px",
});

export const Manifests = () => {
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(14, "d"));
  const [endDate, setEndDate] = useState(moment());
  const queryClient = useQueryClient();

  const { data: manifests, isLoading } = useQuery([QUERIES.Manifests, startDate.toDate(), endDate.toDate()], () => manifestService.getAll(startDate.toDate(), endDate.toDate()));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <>
      <TabContext value={value}>
        <Grid container px={4} pt={2} pb={1} sx={{ backgroundColor: "white" }}>
          <Grid item pb={1}>
            <Button size="large" startIcon={<ChevronLeft />} variant="text" color="primary" component={Link} to={`/`}>
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4" color="text.primary">
              Manifest Overview
            </Typography>
            <Box>
              <DatePicker label="Start Date" value={startDate} sx={{ mr: 2 }} onChange={handleStartDateChange} />
              <DatePicker label="End Date" value={endDate} sx={{ mr: 6 }} onChange={handleEndDateChange} />
              <Button size="large" sx={{ height: "52px" }} startIcon={<Add />} variant="outlined" onClick={handleClickOpen}>
                <Label>ADD NEW MANIFEST</Label>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label={<Label>NEW</Label>} value="1" />
                <Tab label={<Label>COMPLETED</Label>} value="2" />
                <Tab label={<Label>INCOMING</Label>} value="3" />
                <Tab label={<Label>ALL</Label>} value="4" />
              </TabList>
            </Box>
          </Grid>
        </Grid>
        <OverlayLoader isLoading={isLoading} />
        <TabPanel value="1">{manifests && <PaginatedCardsView data={manifests?.data?.result?.filter((x) => x.status.id === MANIFEST_STATUS.DRAFT)} />}</TabPanel>
        <TabPanel value="2">{manifests && <PaginatedCardsView data={manifests?.data?.result?.filter((x) => x.status.id === MANIFEST_STATUS.COMPLETED)} />}</TabPanel>
        <TabPanel value="3">{manifests && <PaginatedCardsView data={manifests?.data?.result?.filter((x) => x.status.id === MANIFEST_STATUS.IN_PROGRESS)} />}</TabPanel>
        <TabPanel value="4">{manifests && <PaginatedCardsView data={manifests?.data?.result} />}</TabPanel>
      </TabContext>
      {open && (
        <ManifestCreateDialog
          open={open}
          handleClose={handleClose}
          onSuccessCallback={() => {
            handleClose();
            enqueueSnackbar("Manifest created successfully!", {
              variant: "success",
            });
            queryClient.invalidateQueries([QUERIES.Manifests]);
          }}
        />
      )}
    </>
  );
};
