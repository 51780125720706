// If you need to support IE11 uncomment the imports below
//import "react-app-polyfill/ie11";
//import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";
import App from "./App";

import { CssBaseline } from "@mui/material";
import Hotjar from "@hotjar/browser";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./contexts/AuthContext";
import { ConfirmProvider } from "material-ui-confirm";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { SnackbarProvider } from "notistack";

const siteId = 3712786;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConfirmProvider>
        <SnackbarProvider autoHideDuration={2000} anchorOrigin={{ horizontal: "right", vertical: "top" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Router>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthProvider>
                  <App />
                </AuthProvider>
              </ThemeProvider>
            </Router>
          </LocalizationProvider>
        </SnackbarProvider>
      </ConfirmProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
