import React from "react";
import { Box, Button, DialogContent, DialogTitle, Grid, Typography, IconButton, Alert } from "@mui/material";
import { Close } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useMutation, useQueryClient } from "react-query";
import manifestService from "../services/manifestService";
import { QUERIES } from "../utils/constants";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

const ManifestEntryStockConfirmStep = ({ manifestEntry, manifestConverter, onBack, onClose, newManifestEntry }) => {
  const queryClient = useQueryClient();

  const migrateCatEntryMutation = useMutation((values) => manifestService.migrateEntryCat(values.catId, values.destinationId), {
    onSuccess: ({}) => {
      queryClient.invalidateQueries([QUERIES.ManifestEntries]);
      queryClient.invalidateQueries([QUERIES.Manifests]);

      enqueueSnackbar("Catalytic Converter Stock Number Changed Successfully.", { variant: "success" });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar("Error changing stock number.", { variant: "error" });
    },
  });

  const onSubmissionConfirm = async () => {
    await migrateCatEntryMutation.mutateAsync({ catId: manifestConverter.id, destinationId: newManifestEntry.id });
  };

  return (
    <>
      <DialogTitle sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={11}>
            <IconButton onClick={() => onBack()} style={{ position: "absolute", top: 24, left: 20 }} size="large" color="primary">
              <ArrowBackIcon sx={{ width: 48, height: 48 }} />
            </IconButton>
            <Typography variant="h3" color="error" mt={6}>
              INCORRECT STOCK NUMBER CORRECTION
            </Typography>
            {manifestEntry?.vehicleId && (
              <Typography variant="h4" color="text.primary" sx={{ mt: 2 }}>
                {manifestEntry?.vehicle?.year} {manifestEntry?.vehicle?.make} {manifestEntry?.vehicle?.model} {manifestEntry?.vehicle?.trim} {manifestEntry?.vehicle?.engineLiters}L {manifestEntry?.vehicle?.engine}
              </Typography>
            )}
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={onClose} style={{ position: "absolute", top: 24, right: 20 }} size="large" color="inherit">
              <Close sx={{ width: 48, height: 48 }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ mt: 4, px: 5 }}>
        <Grid container spacing={3}>
          <Grid item justifyContent="center" xs={12} display={"flex"} flexDirection={"row"}>
            <Box>
              <Typography variant="h4" color="text.primary">
                Original
              </Typography>
              <Typography variant="h3" color="primary">
                #{manifestEntry?.stockNumber}
              </Typography>
            </Box>
            <Box sx={{ mx: 2, display: "flex", alignItems: "center" }}>
              <ArrowForwardIcon sx={{ height: "58px", width: "58px" }} />
            </Box>
            <Box>
              <Typography variant="h4" color="text.primary">
                Corrected
              </Typography>
              <Typography variant="h3" color="primary">
                #{newManifestEntry?.stockNumber}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} justifyContent={"center"} display={"flex"}>
            <Box component="img" src={manifestConverter?.stockTagImage?.url} sx={{ maxWidth: "40%", margin: "auto", borderRadius: 8 }} />
          </Grid>
          <Grid item xs={12} justifyContent="center">
            <Alert variant="filled" severity="error" color="warning" sx={{ alignItems: "center", borderRadius: 4, mt: 4 }} icon={<ErrorOutlineIcon sx={{ width: 80, height: 80 }} />}>
              <Typography variant="h5">You are about to change the current stock number. Please review the information to make sure this is an action you want to perform.</Typography>
            </Alert>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button variant="outlined" fullWidth size="large" sx={{ height: 58 }} onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <LoadingButton variant="contained" fullWidth size="large" sx={{ height: 58 }} loading={migrateCatEntryMutation.isLoading} disabled={migrateCatEntryMutation.isLoading} onClick={() => onSubmissionConfirm()}>
              Submit Correction
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default ManifestEntryStockConfirmStep;
